<template>
  <div>
      <div class="box">
        <div>
            <div class="search">
                <input type="number" class="serachInput" placeholder="请输入MSISDN或者ICCID" name="" id="" value="" />
                <button class="searchButton"><i>搜索</i></button>
            </div>
            <table>
                <tr>
                    <th> MSISDN</th>
                    <th id="MSISDN" ></th>
                </tr>
                <tr>
                    <th>ICCID</th>
                    <th id="ICCID"></th>
                </tr>
                <tr>
                    <th>IMSI</th>
                    <th id="IMSI"></th>

                </tr>
                <tr>
                    <th>运营商</th>
                    <th id="Operator"></th>

                </tr>
                <tr>
                    <th>网络类型</th>
                    <th id="NetworkType"></th>

                </tr>
                <tr>
                    <th>
                        套餐</th>
                    <th id="Setmeal"></th>
                </tr>
                <tr>
                    <th>激活日期</th>
                    <th id="StarData"></th>
                </tr>
                <tr>
                    <th>计费结束日期</th>
                    <th id="EndPriceData"></th>
                </tr>
                <tr>
                    <th>状态</th>
                    <th id="Status"></th>
                </tr>
                <tr>
                    <th>当月已用流量</th>
                    <th id="NowMonyFlow"></th>
                </tr>
                <tr>
                    <th>剩余流量	</th>
                    <th id="SurplusFlow"></th>
                </tr>
                <tr>
                    <th>激活状态</th>
                    <th id="ActivatedState"></th>
                </tr>

            </table>
        </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
data() {
return {
 
}
},
mounted() {
    $(".searchButton").click(function(){
  var $serachInput = $(".serachInput").val();
  if($serachInput !== ""){
    // 获取到值后发送请求
    getAjax($serachInput);
  } 
});
//网络请求
function getAjax(value){
  console.log(value,"发送ajax请求");
 $.ajax({
     url: "https://nnhpapi.nnhpiot.com/cardinfo",
     type:"Post",
     data:{carid:value},
     dataType:"json",
     cache:false,
     success:function(res){
        // var obj = JSON.parse(res);
         console.log(res)
         if(res.data.resultCode==1){
            alert("输入错误或卡号不存在");
         }
         $("#MSISDN").text(res.data.msisdn);
         $("#ICCID").text(res.data.iccid);
         $("#IMSI").text(res.data.imsi);
         $("#Operator").text(res.data.supplier);
         $("#NetworkType").text(res.data.networkType);

         $("#Setmeal").text(res.data.setMeal);
         $("#StarData").text(res.data.activateTime);
         $("#EndPriceData").text(res.data.expiryDate);
         $("#Status").text(res.data.status);
         $("#NowMonyFlow").text(res.data.flow);
         $("#SurplusFlow").text(res.data.residueFlow);
         $("#ActivatedState").text(res.data.active);
        //  请求过来的数据用字符串拼接，然后渲染到页面中
     }
 });
}
},
methods: {
 
}
}

</script>

<style>
table{
    border: 1px solid #cccccc;
    border-collapse: collapse;
    width: 80%;
    font-size: 20px;
    margin: auto;
            }
    th, td{
        border: 1px solid #cccccc;
       width: 50%;
        border-collapse: collapse;
        padding: 10px 20px;
        text-align: left;
    }
    .search{
            width: 40%;   
            font-size: 20px;         
            margin: 100px auto;
            display: flex;
            /*border: 1px solid red;*/
        }
        .search input{
            font-size: 20px;
            float: left;/*左浮动*/
            flex: 4;
            height: 40px;
            outline: none;
            border: 1px solid #51a746;
            box-sizing: border-box;/*盒子模型，怪异IE盒子模型，width=content+border*2+padding*2*/
            padding-left: 10px;
        }
        .search button{
            float: right;
            flex: 1;
            height: 40px;
            background-color: #51a746;
            color: white;
            border-style: none;
            outline: none;
        }
        .search button i{
            font-style: normal;
        }
        .search button:hover{
            font-size: 20px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        input[type="number"]{
            -moz-appearance: textfield;
        }
</style>